.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
  }
  
  .marquee-text {
    display: inline-block;
    /*
    padding-left: 100%;
    animation: scroll 10s linear infinite;
    */
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  